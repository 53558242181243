import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import CardTexture from '../CardTexture'
import CardDefault from '../CardDefault'
import CardHorizontal from '../CardHorizontal'
import CardTile from '../CardTile'
import PostCardTile from '../PostCardTile'

const PostCardModule = (props) => {
  const { postCards, headline, bodyText, textAlign, negativeTopMargin } =
    props.props

  return (
    <>
      <div
        className={`${props.container} card-module ${
          negativeTopMargin && 'mt--160'
        }`}
      >
        {headline || bodyText ? (
          <div className="row justify-content-center">
            <div className={`col-12 py-5 card-headline-col text-${textAlign}`}>
              {headline && (
                <h2
                  className={`d-block bg-white pt-5 ${
                    negativeTopMargin ? 'px-lg-8' : null
                  }`}
                >
                  {headline}
                </h2>
              )}
              {bodyText && (
                <div
                  className={`body-text ${
                    negativeTopMargin ? 'bg-white' : null
                  } ${!headline ? 'pt-lg-5' : null} px-lg-8`}
                >
                  {renderRichText(bodyText)}
                </div>
              )}
            </div>
          </div>
        ) : null}

        {postCards && (
          <div className="row justify-content-center post-card-container">
            {postCards.map((postCard, index) => (
              <PostCardTile props={postCard} key={`${postCard.id}-${index}`} />
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default PostCardModule
