import React from 'react'
import Button from '../Button'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const SplitContent = (props) => {
  const {
    headline,
    leftJustification,
    bodyText,
    button,
    image,
    spMedia,
    imageOn,
    isImageRounded,
    hasCenterElement,
    centerElementImage,
    noAnimation,
  } = props.props

  const splitImage = image && getImage(image.file)
  const centerImage = centerElementImage && getImage(centerElementImage.file)

  return (
    <>
      <div className={`row split-content-row`}>
        {hasCenterElement && (
          <div
            data-aos={noAnimation ? null : 'zoom-in'}
            className="badge badge-circle center-element"
            style={{ backgroundImage: `url(${centerElementImage.file.url})` }}
          ></div>
        )}
        <div
          className={`col-lg-6 text-col order-2 ${
            !image && spMedia ? 'py-6' : ''
          } ${leftJustification && 'text-left'} ${
            hasCenterElement && 'has-element'
          } ${imageOn === true ? 'order-lg-2' : 'order-lg-1'}`}
          data-aos={
            noAnimation ? null : imageOn === true ? 'fade-left' : 'fade-right'
          }
        >
          <h3>{headline}</h3>
          <div className="body-text">
            {renderRichText(bodyText)}
            {button && <Button props={button} />}
          </div>
        </div>
        <div
          className={`col-lg-6 image-col order-1 ${
            imageOn === true ? 'order-lg-1' : 'order-lg-2'
          } `}
          data-aos={
            noAnimation ? null : imageOn === true ? 'fade-right' : 'fade-left'
          }
        >
          {image && !spMedia ? (
            <GatsbyImage
              alt={image.alt}
              image={splitImage}
              className={`img-fluid ${isImageRounded && 'img-rounded'}`}
            />
          ) : null}

          {!image && spMedia ? (
            <video
              playsInline={spMedia.playsInline ? true : null}
              autoPlay={spMedia.autoPlay ? true : null}
              muted={spMedia.muted ? true : null}
              loop={spMedia.loop ? true : null}
              poster={spMedia.postCard?.file.url || null}
              controls={spMedia.controls || null}
              className="video-wrapper"
            >
              <source
                src={spMedia.video.file.url}
                type={spMedia.video.file.contentType}
              />
              Your browser does not support the video tag.
            </video>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default SplitContent
