import React, { Fragment } from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import CardTexture from '../CardTexture'
import CardDefault from '../CardDefault'
import CardHorizontal from '../CardHorizontal'
import CardTile from '../CardTile'

const CardModule = (props) => {
  const { cards, headline, bodyText, textAlign, negativeTopMargin } =
    props.props

  return (
    <>
      <div
        className={`${props.container} card-module ${
          negativeTopMargin && 'mt--160'
        }`}
      >
        {headline || bodyText ? (
          <div className="row justify-content-center">
            <div className={`col-12 pb-4 card-headline-col text-${textAlign}`}>
              {headline && (
                <h2
                  className={`d-block bg-white ${
                    negativeTopMargin ? 'px-lg-8 pt-4 pb-3 mb-0' : null
                  }`}
                >
                  {headline}
                </h2>
              )}
              {bodyText && (
                <div
                  className={`body-text ${
                    negativeTopMargin ? 'bg-white' : null
                  } ${!headline ? 'pt-lg-5' : null} ${
                    props.container !== 'container-fluid' && 'px-lg-8'
                  }`}
                >
                  {renderRichText(bodyText)}
                </div>
              )}
            </div>
          </div>
        ) : null}

        {cards && (
          <div className="row justify-content-center">
            {cards.map((card, index) => (
              <Fragment key={index}>
                {card.cardType === 'Borderless Texture' && (
                  <CardTexture
                    props={card}
                    className="borderlessTexture"
                    key={`${card.id}-${index}`}
                    aosOffset={(index + 1) * 10}
                  />
                )}

                {card.cardType === 'Default' && (
                  <CardDefault
                    props={card}
                    className="default"
                    key={`${card.id}-${index}`}
                    aosOffset={(index + 1) * 10}
                  />
                )}

                {card.cardType === 'Default-Horizontal' && (
                  <CardDefault
                    props={card}
                    className="defaultHorizontal"
                    key={`${card.id}-${index}`}
                    aosOffset={(index + 1) * 10}
                  />
                )}

                {card.cardType === 'Tile' && (
                  <CardTile
                    props={card}
                    className="tile"
                    key={`${card.id}-${index}`}
                    aosOffset={(index + 1) * 100}
                  />
                )}
              </Fragment>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default CardModule
