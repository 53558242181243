import React from "react";
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ImageGrid = (props, container) => {
	
	const {
		bodyText,
		headline,
		media,
		id
	 } = props.props;
	 
	 
 return (
	<div className={`${props.container}  split-content-module`}>
		<div className="row justify-content-center">
			<div className="col-lg-9 text-center">
				<h2>{headline}</h2>
				<div className="body-text text-iac-gray-blue">{renderRichText(bodyText)}</div>
			</div>
		</div>
		<div className="row">
			<div className="col-12 image-grid">
				{media.map((mediaItem) => (
					mediaItem.sys.contentType.sys.id === "image" ? (
						<div key={mediaItem.id} className="cell" style={{
						backgroundImage: `url(${mediaItem.file.url})`}}></div>
					) : (
						<div key={mediaItem.id} className={`cell`} style={{background: `#${mediaItem.hexValue}`}}></div>
					)
				))}
			</div>
		</div>
    </div>
   );
 };
 
 export default ImageGrid;
