import React, { useState } from 'react'
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from '@react-google-maps/api'

import locationIcon from '../../../static/icons/location-icon.png'
import headquarterLocationIcon from '../../../static/icons/headquarter-location-icon.png'

const containerStyle = {
  width: '100%',
  height: '75vh',
}

function LocationMap(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAP_KEY,
  })

  const center = {
    lat: props.center.lat,
    lng: props.center.lon,
  }

  const [map, setMap] = React.useState(null)
  const [activeMarker, setActiveMarker] = useState(null)

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return
    }
    setActiveMarker(marker)
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={props.zoomLevel}
      options={{
        styles: [
          {
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'administrative',
            elementType: 'geometry',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'administrative.neighborhood',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'landscape',
            stylers: [
              {
                color: '#E0E0E0',
              },
            ],
          },
          {
            featureType: 'poi',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'road',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'transit',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'water',
            stylers: [
              {
                color: '#FFFFFF',
              },
            ],
          },
        ],
      }}
      onUnmount={onUnmount}
    >
      {props.locations.map((location, index) => {
        return (
          <Marker
            key={index}
            icon={
              location.headquarters ? headquarterLocationIcon : locationIcon
            }
            position={{
              lat: location.coordinates.lat,
              lng: location.coordinates.lon,
            }}
            onClick={() => handleActiveMarker(index)}
          >
            {activeMarker === index ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <div>
                  <h6>{location.name}</h6>
                </div>
              </InfoWindow>
            ) : null}
          </Marker>
        )
      })}
    </GoogleMap>
  ) : (
    <></>
  )
}

export default React.memo(LocationMap)
