import React from "react";
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Button from "../Button"
import { GatsbyImage, getImage } from "gatsby-plugin-image";


const CardTile = ({props, aosOffset}) => {
	
	const {
		bodyText,
		cardWidth,
		headline,
		image,
		button,
		cardType,
		tileLinkDestination,
		id
	 } = props;
	 
	 
	 let colClass = "";
	 switch (cardWidth) {
		 case "full-width":
		   colClass = "col-12";
		   break;
		 case "1/2":
		   colClass = "col-lg-6";
		   break;
		 case "1/3":
		   colClass = "col-lg-4";
		   break;
		 case "1/4":
		   colClass = "col-lg-3";
		   break;
		 default:
		   colClass = "col-12";
	   }
	   
	   const tileImage = image && getImage(image.file)
	
	
	 
		  
 return (
	<>
		<div className={`${colClass}`}>
			<div  data-aos='fade-up' data-aos-offset={aosOffset} className={`card card-tile bg-cover bg-size--cover hover-scale-105 hover-shadow-lg `}>
				<a href={tileLinkDestination.slug}>
					<GatsbyImage alt={image.alt} image={tileImage} className="img-fluid" />
					<span className="mask bg-black opacity-6"></span>
					
					<div className={`card-body`}>
						<h3 className="headline">{headline}</h3>
						{bodyText && (
							<div className="body-text">
								{renderRichText(bodyText)}
							</div>
						)}
					</div>
				</a>
			</div>
			
	   </div>
	 </>
   );
 };
 
 export default CardTile;
