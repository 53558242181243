import React from 'react'
import { Link } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Button from '../Button'

const CardDefault = ({ props, aosOffset }) => {
  const {
    bodyText,
    cardWidth,
    headline,
    image,
    button,
    cardType,
    id,
    tileLinkDestination,
  } = props

  let colClass = ''
  switch (cardWidth) {
    case 'full-width':
      colClass = 'col-12'
      break
    case '1/2':
      colClass = 'col-lg-6'
      break
    case '1/3':
      colClass = 'col-lg-4'
      break
    case '1/4':
      colClass = 'col-lg-3'
      break
    default:
      colClass = 'col-12'
  }

  const textureImage = image && getImage(image.file)

  return (
    <div className={`${colClass}`}>
      <div
        data-aos="fade-up"
        data-aos-offset={aosOffset}
        className={`card ${
          cardType === 'Default-Horizontal' && 'card-horizontal'
        } card-default`}
      >
        {image &&
          (tileLinkDestination ? (
            <Link to={tileLinkDestination.slug}>
              <div
                className={`card-image ${
                  cardType === 'Default-Horizontal' && 'col-md-4'
                } `}
              >
                <GatsbyImage
                  alt={image.alt}
                  image={textureImage}
                  className="img-fluid"
                />
              </div>
            </Link>
          ) : (
            <div
              className={`card-image ${
                cardType === 'Default-Horizontal' && 'col-md-4'
              } `}
            >
              <GatsbyImage
                alt={image.alt}
                image={textureImage}
                className="img-fluid"
              />
            </div>
          ))}
        <div
          className={`card-body ${
            cardType === 'Default-Horizontal' && 'col-md-8'
          } `}
        >
          {headline && <h4 className="headline">{headline}</h4>}
          {bodyText && (
            <div className="body-text">{renderRichText(bodyText)}</div>
          )}
          {button && <Button props={button} />}
        </div>
      </div>
    </div>
  )
}

export default CardDefault
