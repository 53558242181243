import React from "react";
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const CardTexture = ({props, aosOffset}) => {
	
	const {
		bodyText,
		cardWidth,
		headline,
		image,
		id
	 } = props;
	 
	 let colClass = "";
	 switch (cardWidth) {
		 case "full-width":
		   colClass = "col-12";
		   break;
		 case "1/2":
		   colClass = "col-md-6";
		   break;
		 case "1/3":
		   colClass = "col-md-4";
		   break;
		 case "1/4":
		   colClass = "col-md-3";
		   break;
		 default:
		   colClass = "col-12";
	   }
	 
	 const textureImage = image && getImage(image.file)
	 
 return (
	<>
		<div className={`${colClass}`}>
			<div className="card card-texture" data-aos='fade-up' data-aos-offset={aosOffset}>
				<div className="card-image">
					<GatsbyImage alt={image.alt} image={textureImage} className="img-fluid" />
				</div>
				<div className="card-body">
					<h4>{headline}</h4>
					<div className="body-text">
						{renderRichText(bodyText)}
					</div>
				</div>
			</div>
			
	   </div>
	 </>
   );
 };
 
 export default CardTexture;
