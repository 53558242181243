import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import { GatsbyImage } from 'gatsby-plugin-image'

const BubbleTile = ({ props }) => {
  const { caption, stat } = props

  return (
    <div className="bubble">
      <div className="stat-wrapper">
        <h3 className="stat">{stat}</h3>
      </div>
      <span className="caption">{caption}</span>
    </div>
  )
}

export default BubbleTile
