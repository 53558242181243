import React, { useRef, useState } from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { Navigation, Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Button from '../Button'

const Slider = (props) => {
  const { name, hasNavArrows, hasPagination, slideDuration, slides } =
    props.props

  return (
    <div className="slider">
      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        navigation={hasNavArrows}
        pagination={{
          enabled: hasPagination,
          clickable: true,
        }}
        autoplay={{
          delay: slideDuration,
          pauseOnMouseEnter: false,
        }}
      >
        {slides.map((slide, i) => (
          <SwiperSlide
            key={`${slide.id}-${i}`}
            className="bg-cover bg-size--cover"
            style={{
              backgroundImage: `url(${
                slide.image ? slide.image.file.url : '#'
              })`,
            }}
          >
            <span className="mask bg-black opacity-5"></span>
            <div className="slide-content">
              <h1 className="headline">{slide.headline}</h1>
              <div className="body-text">{renderRichText(slide.bodyText)}</div>
              {slide.button && <Button props={slide.button} />}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Slider
