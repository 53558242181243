import React from 'react'
import TeamMember from '../TeamMember'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const TeamModule = (props, company, container) => {
  const { headline, bodyText, teamMembers } = props.props

  return (
    <div className={`${props.container}  split-content-module`}>
      <div className="row">
        <div className="col-lg-6">
          <div>
            <h2>{headline}</h2>
            <div>{renderRichText(bodyText)}</div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="row">
            {teamMembers.map((teamMember, index) => {
              if (index <= 1) {
                return (
                  <TeamMember
                    key={teamMember.id}
                    props={teamMember}
                    withHeadline={true}
                  />
                )
              }
            })}
          </div>
        </div>
      </div>
      <div className="row">
        {teamMembers.map((teamMember, index) => {
          if (index >= 2) {
            return <TeamMember key={teamMember.id} props={teamMember} />
          }
        })}
      </div>
    </div>
  )
}

export default TeamModule
