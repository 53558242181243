import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import NewsroomFeaturedPost from '../NewsroomFeaturedPost'

const NewsroomFeaturedPosts = (props) => {
  const { name, featuredNewsroomPosts } = props.props

  return (
    <>
      {featuredNewsroomPosts && (
        <>
          <div className={`${props.container} newsroom-featured-posts`}>
            <div className="row justify-content-center">
              {featuredNewsroomPosts.slice(0, 1).map((post, index) => (
                <NewsroomFeaturedPost
                  props={post}
                  key={index}
                  postIndex={index + 1}
                  featureType="primary"
                />
              ))}
              <div className="col-lg-6">
                {featuredNewsroomPosts.slice(1, 4).map((post, index) => (
                  <NewsroomFeaturedPost
                    props={post}
                    key={index}
                    postIndex={index + 1}
                    featureType="secondary"
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="container-fluid py-7 bg-iac-light-gray">
            <div className="container">
              {featuredNewsroomPosts.slice(4, 10).map((post, index) => (
                <div className="row">
                  <NewsroomFeaturedPost
                    props={post}
                    key={index}
                    postIndex={index + 1}
                    featureType="theRest"
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default NewsroomFeaturedPosts
