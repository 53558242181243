import React from "react";
import SplitContent from "../SplitContent"

const SplitContentModule = (props, company, container) => {
	
	const {
		splitContentRow
	 } = props.props;
	 
 return (
	<>
		<div className={`container-fluid split-content-module`}>
			{splitContentRow.map((row) => (
				<SplitContent key={row.id} props={row}  />
			))}
	   </div>
	 </>
   );
 };
 
 export default SplitContentModule;
