import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Button from '../Button'

const Hero = (props) => {
  const { type, headline, bodyText, image, button, heroSize } = props.props

  return (
    <div className="container">
      <div className="row text-center justify-content-center">
        <div className="col-sm-8">
          <h1 className="headline">{headline}</h1>
          <div className="body-text">
            {bodyText && renderRichText(bodyText)}
          </div>
          {button && <Button props={button} />}
        </div>
      </div>
    </div>
  )
}

export default Hero
