import React from 'react'
import { Link } from 'gatsby'

const Button = (props) => {
  const { buttonColor, buttonText, destination } = props.props

  return (
    <Link
      data-aos="zoom-in"
      data-aos-offset="0"
      to={destination}
      className={`btn btn-${buttonColor}`}
    >
      {buttonText}
    </Link>
  )
}

export default Button
