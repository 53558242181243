import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Hero from '../Hero'
import SplitContentModule from '../SplitContentModule'
import FullWidthImageModule from '../FullWidthImageModule'
import PostCardModule from '../PostCardModule'
import CardModule from '../CardModule'
import LocationMap from '../LocationMap'
import ImageGrid from '../ImageGrid'
import TeamModule from '../TeamModule'
import Slider from '../Slider'
import NewsroomFeaturedPosts from '../NewsroomFeaturedPosts'

import BubbleModule from '../BubbleModule'

const ModuleWrapper = (props, type, settings, company) => {
  const { sliceSize, container, extraClasses } = props.props
  const { image, fwMedia } = props.props.module

  useEffect(() => {
    AOS.init({ duration: 600 })
  }, [])

  const slice =
    sliceSize === 'large'
      ? 'slice-lg'
      : sliceSize === 'XL'
      ? 'slice-xl'
      : sliceSize === 'Small'
      ? 'slice-sm'
      : 'slice'
  const containerSize =
    container === 'fluid container'
      ? 'container-fluid'
      : container === 'default'
      ? 'container'
      : 'container-fluid'

  const isHero = props.type === 'hero' ? 'hero' : ''

  return (
    <>
      {props.type === 'hero' && (
        <>
          {props.props.module.type === 'slider' ? (
            <section
              className={`${slice} slice-${props.type} slice-${
                props.props.module.type === 'slider' && 'slider'
              } ${extraClasses}`}
            >
              <Slider props={props.props.module.slider} />
            </section>
          ) : (
            <section
              className={`${slice} slice-${props.type} ${
                props.props.module.image && 'slice-has-image'
              } ${extraClasses} bg-cover bg-size--cover`}
              style={{
                backgroundImage: `url(${
                  props.props.module.image && !props.props.module.hrMedia
                    ? props.props.module.image.file.url
                    : '#'
                })`,
                backgroundPosition: props.props.module.backgroundPosition,
              }}
            >
              {!props.props.module.image && props.props.module.hrMedia ? (
                <video
                  playsInline={
                    props.props.module.hrMedia.playsInline ? true : null
                  }
                  autoPlay={props.props.module.hrMedia.autoPlay ? true : null}
                  muted={props.props.module.hrMedia.muted ? true : null}
                  loop={props.props.module.hrMedia.loop ? true : null}
                  poster={props.props.module.hrMedia.postCard?.file.url || null}
                  controls={props.props.module.hrMedia.controls || null}
                  className="video-wrapper"
                >
                  <source
                    src={props.props.module.hrMedia.video.file.url}
                    type={props.props.module.hrMedia.video.file.contentType}
                  />
                  Your browser does not support the video tag.
                </video>
              ) : null}

              <span className="mask bg-black opacity-5"></span>
              <Hero
                props={props.props.module}
                company={props.company}
                container={containerSize}
              />
            </section>
          )}
        </>
      )}

      {props.type === 'fullScreenImageModule' && (
        <section
          className={`${slice} slice-${props.type} ${
            image && 'slice-has-image'
          } ${
            fwMedia && 'slice-video'
          } ${extraClasses} bg-cover bg-size--cover`}
          style={{
            backgroundImage: `url(${image ? image.file.url : '#'})`,
            backgroundPosition: props.props.module.backgroundPosition,
          }}
        >
          {!image && fwMedia ? (
            <video
              playsInline={fwMedia.playsInline ? true : null}
              autoPlay={fwMedia.autoPlay ? true : null}
              muted={fwMedia.muted ? true : null}
              loop={fwMedia.loop ? true : null}
              poster={fwMedia.postCard?.file.url || null}
              controls={fwMedia.controls || null}
              className="video-wrapper"
            >
              <source
                src={fwMedia.video.file.url}
                type={fwMedia.video.file.contentType}
              />
              Your browser does not support the video tag.
            </video>
          ) : null}

          {props.props.module.hasOverlay && (
            <span className="mask bg-black opacity-6"></span>
          )}
          <FullWidthImageModule
            props={props.props.module}
            company={props.company}
            container={containerSize}
          />
        </section>
      )}

      {props.type != 'hero' && props.type != 'fullScreenImageModule' && (
        <section className={`${slice} slice-${props.type} ${extraClasses}`}>
          {props.type === 'splitContentModule' && (
            <SplitContentModule
              props={props.props.module}
              company={props.company}
              container={containerSize}
            />
          )}

          {props.type === 'imageGrid' && (
            <ImageGrid
              props={props.props.module}
              company={props.company}
              container={containerSize}
            />
          )}

          {props.type === 'teamModule' && (
            <TeamModule
              props={props.props.module}
              company={props.company}
              container={containerSize}
            />
          )}

          {props.type === 'cardModule' && (
            <CardModule
              props={props.props.module}
              company={props.company}
              container={containerSize}
            />
          )}

          {props.type === 'bubbleModule' && (
            <BubbleModule
              props={props.props.module}
              company={props.company}
              container={containerSize}
            />
          )}

          {props.type === 'newsroomPostsFeatured' && (
            <NewsroomFeaturedPosts
              container={containerSize}
              props={props.props.module}
              posts={props.posts}
            />
          )}
          {props.type === 'postCardModule' && (
            <PostCardModule
              props={props.props.module}
              company={props.company}
              container={containerSize}
            />
          )}
          {props.type === 'map' && (
            <div className="container">
              <LocationMap
                center={props.props.module.mapCenter}
                zoomLevel={props.props.module.zoomLevel}
                locations={props.props.module.locations}
                isMarkerShown
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            </div>
          )}
        </section>
      )}
    </>
  )
}

export default ModuleWrapper
