import React from 'react'

const TeamMember = (props) => {
  const { name, title, photo } = props.props

  return (
    <div
      className={`${
        props.withHeadline === true ? 'col-md-6' : 'col-md-3'
      } team-member`}
    >
      <div className="card-body text-center">
        <div className="avatar-parent-child">
          <div
            className="avatar avatar-lg team-photo rounded-circle"
            style={{ backgroundImage: `url(${photo.file.url})` }}
          ></div>
        </div>
        <h5 className="mt-3 mb-0">{name}</h5>
        <span className="d-block text-sm text-muted mb-3">{title}</span>
      </div>
    </div>
  )
}

export default TeamMember
