import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Navigation from '../components/Navigation'
import ModuleWrapper from '../components/ModuleWrapper'
import Footer from '../components/Footer'

import '../scss/starter.scss'

const PageTemplate = ({ data }) => {
  const { slug, settings, seo, company, navigation, modules, footer } =
    data.contentfulPage

  const { edges } = data.allContentfulPost

  const [scroll, setScroll] = useState(false)

  return (
    <Layout slug={slug} settings={settings} seo={seo} scroll={scroll}>
      <Navigation
        company={company}
        settings={settings}
        navigation={navigation}
        slug={slug}
        setScroll={setScroll}
      />
      {modules &&
        modules.map((mWrapper) => {
          return (
            <ModuleWrapper
              type={mWrapper?.module?.sys?.contentType.sys.id}
              props={mWrapper}
              settings={settings}
              company={company}
              key={mWrapper?.id}
              posts={mWrapper?.name === 'Newsroom Posts Wrapper' ? edges : null}
            />
          )
        })}
      <Footer props={footer} company={company} />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      name
      slug
      settings {
        isBlogPost
        navDarkBackground
      }
      seo {
        pageTitle
        description
        robots
        twitterTitle
        twitterImage {
          file {
            url
          }
        }
        twitterCreator
        ogTitle
        ogImage {
          file {
            url
          }
        }
        ogImageWidth
        ogImageHeight
      }

      company {
        id
        name
      }
      navigation {
        id
        name
        logo {
          file {
            url
            gatsbyImageData(formats: WEBP, width: 200)
          }
          alt
        }
        sections {
          id
          name
          showInTopNav
          page {
            name
            slug
            navLabel
            id
          }
          subPages {
            name
            navLabel
            id
            slug
          }
        }
      }
      modules {
        id
        name
        sliceSize
        container
        extraClasses
        sys {
          contentType {
            sys {
              id
            }
          }
        }
        module {
          ... on ContentfulHero {
            id
            name
            type
            backgroundPosition
            bodyText {
              raw
            }
            headline
            button {
              buttonColor
              buttonText
              destination
              id
            }
            image {
              file {
                url
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            hrMedia {
              autoPlay
              loop
              muted
              playsInline
              showControls
              video {
                file {
                  contentType
                  url
                }
              }
              postCard {
                file {
                  url
                }
              }
            }
            slider {
              name
              hasNavArrows
              hasPagination
              slideDuration
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              slides {
                id
                bodyText {
                  raw
                }
                image {
                  file {
                    url
                  }
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
                button {
                  buttonColor
                  buttonText
                  name
                  destination
                }
                headline
                name
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulNewsroomPosts {
            id
            name
            maxPosts
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulNewsroomPostsFeatured {
            id
            name
            featuredNewsroomPosts {
              title
              slug
              body {
                raw
              }
              publishDate(formatString: "MM-D-yyyy")
              publishDateForList: publishDate(formatString: "MMM DD, YYYY")
              image {
                file {
                  id
                  url
                  gatsbyImageData
                }
                alt
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulSplitContentModule {
            id
            name
            splitContentRow {
              leftJustification
              bodyText {
                raw
              }
              button {
                buttonColor
                buttonText
                destination
                id
              }
              headline
              noAnimation
              id
              imageOn
              spMedia {
                autoPlay
                loop
                muted
                playsInline
                showControls
                video {
                  file {
                    contentType
                    url
                  }
                }
                postCard {
                  file {
                    url
                  }
                }
              }
              isImageRounded
              image {
                alt
                file {
                  gatsbyImageData(layout: FULL_WIDTH)
                  description
                  url
                  publicUrl
                }
              }
              hasCenterElement
              centerElementImage {
                alt
                file {
                  gatsbyImageData(width: 160)
                  description
                  url
                  publicUrl
                }
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulFullWidthModule {
            id
            name
            headline
            backgroundPosition
            hasOverlay
            leftJustification
            bodyText {
              raw
            }
            button {
              buttonColor
              buttonText
              destination
              id
            }
            image {
              alt
              file {
                gatsbyImageData
                url
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            noAnimation
            fwMedia {
              autoPlay
              loop
              muted
              playsInline
              showControls
              video {
                file {
                  contentType
                  url
                }
              }
              postCard {
                file {
                  url
                }
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulTeamModule {
            id
            name
            bodyText {
              raw
            }
            headline
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            teamMembers {
              id
              name
              title
              photo {
                alt
                file {
                  url
                  gatsbyImageData
                }
              }
            }
          }
          ... on ContentfulImageGrid {
            id
            name
            bodyText {
              raw
            }
            headline
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            media {
              ... on ContentfulColor {
                id
                name
                hexValue
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
              }
              ... on ContentfulImage {
                id
                name
                alt
                file {
                  url
                  gatsbyImageData
                }
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
              }
            }
          }
          ... on ContentfulCardModule {
            id
            name
            headline
            bodyText {
              raw
            }
            negativeTopMargin
            textAlign
            cards {
              bodyText {
                raw
              }
              cardType
              cardWidth
              headline
              id
              image {
                alt
                id
                file {
                  gatsbyImageData
                  url
                }
              }
              button {
                buttonColor
                buttonText
                destination
                id
              }
              tileLinkDestination {
                slug
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulBubbleModule {
            id
            name
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            negativeTopMargin
            headline
            textAlign
            bubbles {
              caption
              name
              stat
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              id
            }
          }
          ... on ContentfulMap {
            id
            name
            type
            mapCenter {
              lat
              lon
            }
            zoomLevel
            locations {
              name
              coordinates {
                lat
                lon
              }
              headquarters
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
        }
      }
      footer {
        contactBlock {
          raw
        }
        copyright
        footerNavPages {
          navLabel
          slug
          id
        }
        socialLinks {
          name
          link
          platform
          id
        }
        logo {
          alt
          file {
            gatsbyImageData(height: 24)
          }
        }
        legalPages {
          navLabel
          slug
          id
        }
      }
    }
    allContentfulPost(sort: { fields: publishDate, order: DESC }) {
      edges {
        node {
          id
          slug
          title
          publishDate(formatString: "MM-D-yyyy")
          publishDateForList: publishDate(formatString: "MMM DD, YYYY")
          image {
            alt
            file {
              gatsbyImageData
              url
            }
          }
        }
      }
    }
  }
`

export default PageTemplate
