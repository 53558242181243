import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Button from '../Button'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { richTextOptions } from '../../utils/richTextOptions'

const PostCardTile = ({ props, aosOffset }) => {
  const { bodyText, title, image, destination, openInNewTab } = props

  const tileImage = image && getImage(image.file)

  return (
    <>
      <div className={`post-card`}>
        <GatsbyImage
          alt={image.description}
          image={image.gatsbyImageData}
          className="img-fluid"
        />

        <div className={`card-body`}>
          <h5 className="title">{title}</h5>
          {bodyText && (
            <div className="body-text">
              {renderRichText(bodyText, richTextOptions)}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default PostCardTile
