import React from 'react'
import { Link } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Button from '../Button'

const NewsroomFeaturedPost = ({ props, postIndex, featureType }) => {
  const { title, slug, publishDate, publishDateForList, image, body, excerpt } =
    props

  const postImage = image && getImage(image.file)

  const button = {
    buttonColor: 'black',
    buttonText: 'Read More',
    destination: slug,
  }

  return (
    <>
      {featureType === 'primary' && (
        <div className={`col-lg-6 mb-5`}>
          <div className={`featured-post main-feature`}>
            {image ? (
              <div className="card-image">
                <Link to={slug} title={`View ${title}`}>
                  <GatsbyImage
                    alt={image.alt}
                    image={postImage}
                    className="img-fluid"
                  />
                </Link>
              </div>
            ) : null}

            <div className={`card-body`}>
              <Link to={slug} className="text-black" title={`View ${title}`}>
                <h3 className="headline">{title}</h3>
              </Link>
              {excerpt && (
                <div className="body-section">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: excerpt.childMarkdownRemark.html,
                    }}
                  />
                </div>
              )}
              <Button props={button} />
            </div>
          </div>
        </div>
      )}

      {featureType === 'secondary' && (
        <div className={`col-lg-12`}>
          <div className="row featured-post pb-5">
            {image ? (
              <div className="col-4">
                <Link to={slug} title={`View ${title}`}>
                  <GatsbyImage
                    alt={image.alt}
                    image={postImage}
                    className="img-fluid"
                  />
                </Link>
              </div>
            ) : null}

            <div
              className={`d-flex flex-column justify-content-center ${
                image ? 'col-8' : 'col-8 offset-4'
              }`}
            >
              <Link to={slug} className="text-black" title={`View ${title}`}>
                <h5 className="headline">{title}</h5>
              </Link>
              {excerpt && (
                <div className="body-section">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: excerpt.childMarkdownRemark.html,
                    }}
                  />
                </div>
              )}
              <div>
                <Button props={button} />
              </div>
            </div>
          </div>
        </div>
      )}

      {featureType === 'theRest' && (
        <div className={`col-lg-12`}>
          <div className="row featured-post pb-5">
            {image ? (
              <div className="col-4">
                <Link to={slug} title={`View ${title}`}>
                  <GatsbyImage
                    alt={image.alt}
                    image={postImage}
                    className="img-fluid"
                  />
                </Link>
              </div>
            ) : null}

            <div
              className={`d-flex flex-column justify-content-center ${
                image ? 'col-8' : 'col-8 offset-4'
              }`}
            >
              <Link to={slug} className="text-black" title={`View ${title}`}>
                <h5 className="headline">{title}</h5>
              </Link>
              {excerpt && (
                <div className="body-section">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: excerpt.childMarkdownRemark.html,
                    }}
                  />
                </div>
              )}
              <div>
                <Button props={button} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default NewsroomFeaturedPost
