import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import BubbleTile from '../BubbleTile'

const BubbleModule = (props) => {
  const { bubbles, headline, bodyText, textAlign, negativeTopMargin } =
    props.props

  return (
    <>
      <div
        className={`${props.container} postCard-module ${
          negativeTopMargin && 'mt--160'
        }`}
      >
        {headline || bodyText ? (
          <div className="row justify-content-center">
            <div className={`py-5 postCard-headline-col text-${textAlign}`}>
              {headline && (
                <h2
                  className={`d-block bg-white pt-5 ${
                    negativeTopMargin ? 'px-lg-8' : null
                  }`}
                >
                  {headline}
                </h2>
              )}
              {bodyText && (
                <div
                  className={`body-text ${
                    negativeTopMargin ? 'bg-white' : null
                  } ${!headline ? 'pt-lg-5' : null} px-lg-8`}
                >
                  {renderRichText(bodyText)}
                </div>
              )}
            </div>
          </div>
        ) : null}

        {bubbles && (
          <div className="row justify-content-center bubbles-container mx-0">
            {bubbles.map((bubble, index) => (
              <BubbleTile
                props={bubble}
                className="tile"
                key={`${bubble.id}-${index}`}
                aosOffset={(index + 1) * 100}
              />
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default BubbleModule
